import React from 'react';
import './resumo.scss'

//---ASSETS---
import bigdataImg from '../../assets/imgs/bigdata.webp'
import iaImg from '../../assets/imgs/ia.webp'
import reloadImg from '../../assets/imgs/reload.webp'
import roboImg from '../../assets/imgs/robo_ia.webp'

function Resumo() {
    return ( 
        <div className="resumo">
            
            <img className="robo" src={roboImg} alt="robo..." />
            <div className="row">
                <div className="module">
                    <img src={bigdataImg} alt="big data..." className='bigdata'/>
                   <h2>Big data</h2>
                    <p>Trabalhamos com Big Data para oferecer apoio nas suas decisões.</p>
                </div> 
                <div className="module">
                    <img src={iaImg} alt="ia..." />
                    <h2>Inteligência artificial</h2>
                    <p>Trabalhamos com inteligência artificial para prever o futuro.</p>
                </div> 
                <div className="module">
                    <img src={reloadImg} alt="reload..." />
                    <h2>Sistemas inteligentes</h2>
                    <p>Desenvolvemos sistemas para simplificar os fluxos de sua organização.</p>
                </div>    
            </div> 
            
        </div>
     );
}

export default Resumo;