import React, {useState, useEffect} from 'react';
import './app.scss';

//---COMPONENTS---
import Header from '../components/header'
import Footer from '../components/footer'

//---PAGES---
import Inicio from '../pages/inicio'
import Resumo from '../pages/resumo'
import Workflow from '../pages/workflow'
import Projetos from '../pages/projetos'
import Contato from '../pages/contato';

//---REACT SCROLL---
var Scroll = require('react-scroll')
var Element = Scroll.Element;
var scroller = Scroll.scroller;

function App() {
  const [offset, setOffset] = useState(0);
  const [level, setLevel] = useState(['active','','',''])

  const resumoOffset = document.getElementById("resumo") ? document.getElementById("resumo").offsetTop : 0;
  const workflowOffset = document.getElementById("workflow") ? document.getElementById("workflow").offsetTop : 0;
  const projetosOffset = document.getElementById("projetos") ? document.getElementById("projetos").offsetTop : 0;
  const contatoOffset = document.getElementById("contato") ? document.getElementById("contato").offsetTop : 0;

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
    setLevel(['active','','',''])
  }, []);
  
  useEffect(() => {
    if(offset){
      if(offset > -500)
        setLevel(['active','','',''])
  
      if(offset > workflowOffset - 550)
        setLevel(['','active','',''])
  
      if(offset > projetosOffset - 550)
        setLevel(['','','active',''])
  
      if(offset > contatoOffset - 550)
        setLevel(['','','','active'])
    }
    
  }, [offset]);

  function scrollToInicio(name){
    let offset = -100;
    if(name === 'projetos'){
      offset = 60;
    }

    scroller.scrollTo( name , {
      duration: 500,
      smooth: true,
      offset
    })
  }

  return (
    <div className="app">

      <Header scrollTo={scrollToInicio} level={level} offset={offset}/>

      <div className="conteudo">
        <Element name="inicio" id="inicio">
          <Inicio scrollTo={scrollToInicio}/>
        </Element>
        <Element name="resumo" id="resumo">
          <Resumo/>
        </Element>
        <Element name="workflow" id="workflow">
          <Workflow/>
        </Element>
        <Element name="projetos" id="projetos">
          <Projetos/>
        </Element>
        <Element name="contato" id="contato">
          <Contato/>
        </Element>
        
      </div>
      <Footer/>

    </div>
  );
}

export default App;
