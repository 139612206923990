import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const blinkTextCursor = keyframes`
  from {border-right-color: rgba(0, 0, 0, .75);}
  to {border-right-color: transparent}
`;

const TextCursor = styled.span`
  border-right: 2px solid rgba(0, 0, 0, .75);
  display: inline;
  animation: ${blinkTextCursor} 0.7s steps(44) infinite normal;
`;

const Container = styled.p`
  opacity: 0.7;
  font-weight: 300;
  display: inline-block;
  margin: 0;
`;

function TypeWriter({ value }) {
  const [text, setText] = useState('');
  const [level, setLevel] = useState(1)

  const typeWriter = (text, i = 0) => {
    if (i < text.length) {
      setText(text.slice(0, i + 1));
      setTimeout(() => {
        typeWriter(text, i + 1);
      }, 50);
    }
    else{  
      setTimeout(() => {
        if(level === 2)
          setLevel(1)
        setLevel(level + 1)
      }, 1500);
      
    }
  };

  useEffect(() => {
    typeWriter(value[0]);
   
  }, []);

  useEffect(() => {
    if(level === 1)
      typeWriter(value[0]);
    if(level === 2)
      typeWriter(value[1]);
  }, [level]);

  return (
    <Container>
      {text}
    </Container>
  );
}
export default TypeWriter;