import React from 'react';
import './workflow.scss'

function Workflow() {
    return ( 
        <div className="workflow">
            <div className="text-container">
                <div className="title">
                    <h2> Workflow </h2>
                    <div className="bottom-line"/>
                </div>
                <div className="text">
                    Desenvolvemos soluções de qualquer tamanho sob demanda para organizações no mundo inteiro. Podemos ajudar sua organização adaptando seus fluxos ao sistema.
                    Saiba mais como é o nosso Workflow?
                </div>           
            </div>

            <div className="main">
                <div className="timeline">
                    <div className="module">
                        <div className="circle"> 1 </div>
                        <div className="sub-circle"/>
                        <div className="line"/>
                    </div>
                    <div className="module">
                        <div className="circle"> 2 </div>
                        <div className="sub-circle"/>
                        <div className="line"/>
                    </div>
                    <div className="module">
                        <div className="circle"> 3 </div>
                        <div className="sub-circle"/>
                        <div className="line"/>
                    </div>
                    <div className="module">
                        <div className="circle"> 4 </div>
                        <div className="sub-circle"/>
                        <div className="line"/>
                    </div>
                </div>

                <div className="content">

                    <div className="module">
                        <h2 className="title"> 
                            Discussão da idéia 
                        </h2>
                        <div className="text">
                            O primeiro passo é discutir a idéia e extrair todos os requisitos para o desenvolvimento. Aqui poderão surgir outras idéias com soluções ainda mais otimizadas.
                        </div>
                    </div>
                    <div className="module">
                        <h2 className="title"> 
                            Desenvolvimento
                        </h2>
                        <div className="text">
                            Aqui iniciamos o desenvolvimento e colocamos a mão na massa. Procuramos manter o cliente sempre iterado ao máximo na etapa de desenvolvimento, para que tudo saia como planejado.
                        </div>
                    </div>
                    <div className="module">
                        <h2 className="title"> 
                            Revisões, correções e produção
                        </h2>
                        <div className="text">
                            Nessa etapa vamos refinar o projeto para que tudo funcione como o esperado. E finalmente ele será disponibilizado em um ambiente de produção pronto para ser utilizado.
                        </div>
                    </div>
                        
                    <div className="module">
                        <h2 className="title"> 
                            Entrega
                        </h2>
                        <div className="text">
                            Na última fase entregamos o projeto ao cliente, fazemos o treinamento da equipe e decidimos como iremos dar a manutenção ao projeto.
                        </div>
                    </div>                             
                </div>
                
            </div>
        </div>
     );
}

export default Workflow;