import ReCAPTCHA from 'react-google-recaptcha'

const Recaptcha = (props) => {
  function onChange(value) {
    props.getToken(value)
  }
  return (
    <div className="App">
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={onChange}
      />
    </div>
  );
};
export default Recaptcha;