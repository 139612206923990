import React, {useState} from 'react';
import './header.scss'

//---ASSETS---
import icon from '../../assets/imgs/brainy_sec.webp'
import menuIcon from '../../assets/imgs/menu.webp'

function Header(props) {
    const [menuIsActive, setMenuIsActive] = useState(false)

    let isFixed = false

    if(props.offset === 0)
        isFixed = true;
    

    return ( 
        <header className={isFixed ? 'fixed' : ''}>
            <div className="header-container">
                <a href="/">
                    <img src={icon} alt="icon..." />
                </a>

                <div className="mobile-menu-icon" >
                    <img onClick={ () => {setMenuIsActive(!menuIsActive)}} src={menuIcon} alt="loading..." />
                </div>

                <ul className={`btn-container ${menuIsActive ? 'active': ''}`}>
                    <li>
                        <button 
                            className={`inicio-btn ${props.level[0]}`} 
                            onClick={e => props.scrollTo('inicio')}
                        > 
                            Início <div className="bar"/> 
                        </button>
                    </li>
                    <li>
                    <button 
                        className={`workflow-btn ${props.level[1]}`} 
                        onClick={e => props.scrollTo('workflow')}
                    > 
                        Workflow <div className="bar"/> 
                    </button>
                    </li>
                    <li>
                        <button 
                            className={`projetos-btn ${props.level[2]}`} 
                            onClick={e => props.scrollTo('projetos')}
                        > 
                            Projetos <div className="bar"/> 
                        </button>
                    </li>   
                    <li>
                        <button 
                            className={`projetos-btn ${props.level[3]}`} 
                            onClick={e => props.scrollTo('contato')}
                        > 
                            Contato <div className="bar"/> 
                        </button>
                    </li>               
                </ul>
            </div>        
        </header>
     );
}

export default Header;