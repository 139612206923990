import React from 'react';
import './projetos.scss'

//---ASSETS---
import intimaaiImg from '../../assets/imgs/logo_intimaai.webp'
import athomImg from '../../assets/imgs/logo_athom.webp'

function Projetos() {
    return ( 
        <div className="projetos">

            <div className="custom-shape-divider-bottom-1640098011">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>

            <div className="content">

                <div className="title">
                    <h2> Projetos</h2>
                    <div className="bottom-line"/>
                </div>

                <div className="projetos-container">
                    <a href="https://intima.ai/#home" target="_blank" className="container intimaai">
                        <div className="main">
                            <div className="img-container">
                                <img src={intimaaiImg} alt="intima.ai..." loading='lazy'/>
                            </div>       
                        </div>
                    </a>

                    <a href="https://athom.app" target="_blank" className="container athom">
                        <div className="main">   
                            <div className="img-container">
                                <img src={athomImg} alt="athom..." loading='lazy'/>
                            </div>                        
                        </div> 
                    </a>
                </div>
                

            </div>
        </div>
     );
}

export default Projetos;