import React, {useState, useRef} from 'react';
import './contato.scss'

//---PLUGINS---
import Recaptcha from './recaptcha';

//---ASSETS---
import vetorImg from '../../assets/imgs/contato.webp'

function Contato() {
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [assunto, setAssunto] = useState('')
    const [mensagem, setMenssagem] = useState('')
    const [token, setToken] = useState();

    const recaptchaRef = React.useRef();

    const onSubmit = async (event) => {
        event.preventDefault()
        console.log(token)
    }

    function getToken(value){
        setToken(value)
    }

    return ( 
        <div className="contato">
            <img src={vetorImg} alt="contato..." />
            <form>
                <div className="row">
                    <h2>Envie-nos uma mensagem:</h2>
                </div>
                <div className="row">
                    <input type="text" placeholder="Nome..." name="name" value={nome} onChange={e => {setNome( e.target.value )}}/>
                    <input type="text" placeholder="E-mail" name="email" value={email} onChange={e => {setEmail( e.target.value )}}/>
                </div>
                <div className="row">
                    <input type="text" placeholder="Assunto" name="subject" value={assunto} onChange={e => {setAssunto( e.target.value )}}/>
                </div>
                <div className="row">
                    <textarea type="text" placeholder="Mensagem" name="message" value={mensagem} onChange={e => {setMenssagem( e.target.value )}}/>
                </div>

                <div className="row btn">
                    <Recaptcha
                        getToken={getToken}
                    />
                    <button onClick={e => onSubmit(e)}>Enviar</button>
                </div>
            </form>
        </div>
     );
}

export default Contato;