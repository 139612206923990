import React from 'react';
import './footer.scss'

function Footer() {
    return ( 
        <footer>
            <div className="email"> contato@brainy.digital <div className="bottom-bar"/> </div>
            <div className="copyright"> 
                © 2021. &nbsp;
                <a href="https://brainy.digital/">
                    Brainy Serviços Inteligentes.
                </a>
                &nbsp; Todos os direitos reservados.
            </div>
        </footer>
     );
}

export default Footer;