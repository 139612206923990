import React from 'react';
import './inicio.scss'

//---ASSETS---
import vetorInicioImg from '../../assets/imgs/vetor_inicio.webp'

//---COMPONENTES---
import TypeWriter from '../../components/typewriter'
import IntercomBtn from '../../components/intercom'


function Inicio(props) {

    return ( 
        <div className="inicio" id="example">
            <div className="main">
                <div className="slogan">
                    <h2 className="title">
                        Oferecemos:
                    </h2>

                    <div className="troca">
                        <TypeWriter value={['Big data e Inteligência artificial aplicada ao setor jurídico',
                                            'Sistemas inteligentes, seguros e altamente escaláveis']}/>
                    </div>

                    <p className="light"> Atendemos necessidades complexas tornando-as <br/> simples para o seu dia a dia.</p>
                    
                    <button className="btn-explore" onClick={e => props.scrollTo('contato')}>
                        <ul>
                            <li>
                                Fale conosco
                                <span></span><span></span><span></span><span></span>
                            </li>    
                        </ul>
                    </button>

                </div>
                <div className="vetor-img">
                    <img src={vetorInicioImg} alt="vetor inicio..." />
                </div>   
                          
            </div>

            <div className="custom-shape-divider-bottom-1640098011">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>
        </div>
     );
}

export default Inicio;